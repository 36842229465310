import React, { FormEvent } from "react";

import { UrlInput } from "../../../../../shared/components/design-system/TextInput/LinkInput";
import StringInput from "../../../../../shared/components/design-system/TextInput/StringInput";
import ButtonSet, { Spacer } from "../../../../../shared/components/design-system/component-groups/ButtonSet";
import useAsyncFunctionWithLoading, { useAsyncCallbackWithLoading } from "../../../hooks/useAsyncFunctionWithLoading";
import Button from "../../design-system/Button";
import ConfirmDeleteButton from "../../design-system/Button/specifics/ConfirmDeleteButton";
import Card from "../../design-system/Card";
import Container, { ContainerSize } from "../../design-system/Container";
import CombinedHeader from "../../design-system/PageHeader/CombinedHeader";
import useBreadcrumbs from "../useBreadcrumbs";
import { usePersistence } from "./persistence";

export default function InsightsExternalLinkPage() {
  const {
    name,
    setName,
    externalLinkUrl,
    setExternalLinkUrl,
    loading,
    saveFormRef,
    savedDocument: doc,
    del,
    save,
  } = usePersistence();

  const [wrappedDelete, deleting] = useAsyncFunctionWithLoading(del);

  const [onSubmit, publishing] = useAsyncCallbackWithLoading(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      await save({}, "savedLink");
    },
    [save],
  );

  const breadcrumbs = useBreadcrumbs({ siteTab: "insights" });

  return (
    <Container size={ContainerSize.Medium}>
      <CombinedHeader breadcrumbs={breadcrumbs} title={doc ? doc.name : "Create link"} />
      <form onSubmit={onSubmit} ref={saveFormRef}>
        <Card>
          <StringInput placeholder="Page Name" label="Name" value={name} onChange={setName} required />
          <UrlInput
            placeholder="https://www.example.com/"
            label="Link URL"
            value={externalLinkUrl ?? ""}
            onChange={setExternalLinkUrl}
            required
          />
        </Card>
        <ButtonSet className="ds-mt-7">
          {doc ? (
            <ConfirmDeleteButton
              title="Delete link?"
              variant="dangerTertiary"
              track="Insight reports"
              loading={deleting}
              disabled={loading}
              type="button"
              onConfirm={() => wrappedDelete("deletedLink")}
              confirmBody="This will permanently delete this link"
              confirmText="Delete"
            >
              Delete link
            </ConfirmDeleteButton>
          ) : null}
          <Spacer />
          <Button
            className="ds-ml-auto"
            type="submit"
            variant="primary"
            loadingText="Saving"
            disabled={loading}
            loading={publishing}
          >
            Save
          </Button>
        </ButtonSet>
      </form>
    </Container>
  );
}
